import React from 'react'

import Breadcrumb from '../components/breadcrumb/breadcrumb'
import Layout from '../components/layout/layout'
import PageHeader from '../components/layout/page-header'
import SEO from '../components/seo'

const PrivacyPage = () => {
  const title = 'Privacy Policy'

  return (
    <>
      <SEO title={title} />
      <Layout>
        <Breadcrumb activeItem={title} />
        <PageHeader>{title}</PageHeader>

        <article>
          <p>
            Ow’doo! This privacy policy sets out how The Lancashire Hotpots uses
            and protects any information that you give The Lancashire Hotpots
            when you use this website or consent to share your information with
            us.
          </p>

          <p>
            The Lancashire Hotpots is committed to ensuring that your privacy is
            protected. Should we ask you to provide certain information by which
            you can be identified then you can be assured that it will only be
            used in accordance with this privacy statement.
          </p>

          <p>
            The Lancashire Hotpots may change this policy from time to time by
            updating this page. You should check this page from time to time to
            ensure that you are happy with any changes. This policy is effective
            from 22/05/18.
          </p>

          <h3>What we collect</h3>

          <p>We may collect the following information:</p>

          <ul>
            <li>
              Email address when signing up to our mailing list or when you
              receive a free piece of content or any other medium where you
              consent to share your information with us
            </li>
          </ul>

          <h3>What we do with the information we gather</h3>

          <p>
            Below is a list of technologies we may share your data with and what
            purpose we share it for:
          </p>

          <ul>
            <li>
              <dl>
                <dt>Platform:</dt> <dd>Facebook/Instagram Ads</dd>
                <dt>Data shared:</dt> <dd>Email address</dd>
                <dt>Purpose:</dt>{' '}
                <dd>
                  To match your email address with your Facebook profile to
                  serve you relevant content.
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>Platform:</dt> <dd>Mailchimp</dd>
                <dt>Data shared:</dt> <dd>Email address</dd>
                <dt>Purpose:</dt>{' '}
                <dd>
                  To send you emails about upcoming shows or to keep you up to
                  date with our fun adventures.
                </dd>
              </dl>
            </li>
          </ul>

          <p>We may also use it for:</p>

          <ul>
            <li>Internal record keeping.</li>
            <li>
              We may use the information to improve our products and services.
            </li>
          </ul>

          <h3>Security</h3>

          <p>
            We are committed to ensuring that your information is secure. To
            prevent unauthorised access or disclosure, we have put in place
            suitable physical, electronic and managerial procedures to safeguard
            and secure the information we collect online.
          </p>

          <h3>How we use cookies</h3>

          <p>
            A cookie is a small file which asks permission to be placed on your
            computer’s hard drive. Once you agree, the file is added and the
            cookie helps analyse web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>

          <p>
            We use traffic log cookies to identify which pages are being used.
            This helps us analyse data about web page traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
          </p>

          <p>
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>

          <p>
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
          </p>

          <h3>Links to other websites</h3>

          <p>
            Our website may contain links to other websites of interest such as
            venues or ticketing agency pages. Once you have used these links to
            leave our site, you should note that we do not have any control over
            those websites. We cannot therefore be responsible for the
            protection and privacy of any information which you provide whilst
            visiting such sites and such sites are not governed by this privacy
            statement. You should exercise caution and look at the privacy
            statement applicable to the website in question.
          </p>

          <h3>Controlling your personal information</h3>

          <p>
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>

          <ul>
            <li>
              If you have previously agreed to us using your personal
              information to receive emails, you may change your mind at any
              time by unsubscribing through the link at the bottom of all our
              emails or by emailing us directly at{' '}
              <a href='mailto:emmetbath@hotmail.com'>emmetbath@hotmail.com</a>
            </li>
            <li>
              We will not sell, distribute or lease your personal information to
              third parties unless we have your permission or are required by
              law to do so.
            </li>
          </ul>

          <p>
            You may request details of personal information which we hold about
            you under the Data Protection Act 1998. A small fee will be payable.
            If you would like a copy of the information held on you please write
            to:
          </p>

          <address>
            The Lancashire Hotpots c/o GS Concert Promotions Ltd Lakeside,
            Alexandra Business Park, St Helens WA10 3TP.
          </address>

          <p>
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect.
          </p>
        </article>
      </Layout>
    </>
  )
}

export default PrivacyPage
